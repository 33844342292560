var tableColumns = [{
  title: "广告ID",
  dataIndex: "taskid",
  key: "taskid",
  width: 140
}, {
  title: "广告主昵称",
  dataIndex: "advertisername",
  key: "advertisername",
  width: 120,
  scopedSlots: {
    customRender: "advertisername"
  }
}, {
  title: "广告任务名称",
  dataIndex: "taskname",
  key: "taskname",
  width: 100
}, {
  title: "推广计费方式",
  dataIndex: "costtype",
  key: "costtype",
  width: 100,
  scopedSlots: {
    customRender: "costtype"
  }
}, {
  title: "转化组件",
  dataIndex: "conversionwidget",
  key: "conversionwidget",
  width: 180
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "奖金池",
  dataIndex: "totalfunding",
  key: "totalfunding",
  width: 180,
  scopedSlots: {
    customRender: "totalfunding"
  }
}, {
  title: "参与截止时间",
  dataIndex: "endtime",
  key: "endtime",
  width: 120,
  scopedSlots: {
    customRender: "endtime"
  }
}, {
  title: "申请时间",
  dataIndex: "applytime",
  key: "applytime",
  width: 110,
  scopedSlots: {
    customRender: "applytime"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { tableColumns };